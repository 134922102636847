export enum PageSteps {
  planSelect,
  categories,
  kids,
  signUp,
  login,
  delivery,
  payment,
  purchaseConfirmation,
  purchaseError
}
