import { ToastContainer } from 'react-toastify'

import { useAppSelector } from 'hooks/useStore'
import { selectToast } from 'store/modules/Toast.store'

import 'react-toastify/dist/ReactToastify.css'

export function NotificationToast() {
  const { toast } = useAppSelector(selectToast)

  return (
    <ToastContainer
      toastStyle={{
        backgroundColor: toast.color.background,
        color: toast.color.text,
        width: 'max-content',
        borderRadius: '15px'
      }}
      progressStyle={{ backgroundColor: toast.color.progressStyle }}
      position={toast.position}
      hideProgressBar={toast.hideProgressBar}
    />
  )
}
