import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICustomParams } from 'services/api/insider'
import { RootState } from 'store'

type CrmEvents = {
  events?: string[]
  dataEvents?: ICustomParams
}

const initialState: CrmEvents = {
  events: [],
  dataEvents: {}
}

const crmEvents = createSlice({
  name: 'crmEvents',
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<string | null>) => {
      if (action.payload === null || typeof state.events == 'undefined') {
        state.events = []
      } else {
        state.events.push(action.payload)
      }
    },
    setDataToEvent: (state, action: PayloadAction<ICustomParams>) => {
      state.dataEvents = action.payload
    },
    reset: () => initialState
  }
})

export const { setEvent, setDataToEvent, reset } = crmEvents.actions

export const selectCrmEvents = (state: RootState) => state.crmEvents

export default crmEvents.reducer
