export function get<T = unknown>(key: string, isJSON = true): T {
  if (isJSON) {
    return JSON.parse(localStorage.getItem(key) || 'null')
  }
  return localStorage.getItem(key) as unknown as T
}

export function set<T = unknown>(key: string, value: T, isJSON = true): T {
  if (isJSON) {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    localStorage.setItem(key, value as unknown as string)
  }
  return value
}

export function remove(key: string): void {
  localStorage.removeItem(key)
}

export function clear(): void {
  localStorage.clear()
}
