import sidebar from 'translations/locales/en/sidebar'
import notFound from 'translations/locales/en/notFound'
import home from 'translations/locales/en/home'
import labels from 'translations/locales/en/labels'

export default {
  'en-US': {
    sidebar,
    notFound,
    home,
    labels
  }
}
