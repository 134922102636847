import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { PageSteps, UserSimple, LoadingType } from 'types'

type StepsState = {
  orderId: number
  cid: number
  pageStep: PageSteps
  token: string
  user: UserSimple
  loading: LoadingType
}

const initialState: StepsState = {
  orderId: 0,
  cid: 0,
  pageStep: PageSteps.planSelect,
  token: '',
  user: {
    id: 0,
    name: '',
    phone: '',
    email: '',
    marketingOptin: undefined
  },
  loading: {
    status: false,
    message: undefined
  }
}

const steps = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setSubscriptionId: (state, action: PayloadAction<number>) => {
      state.orderId = action.payload
    },
    setCid: (state, action: PayloadAction<number>) => {
      state.cid = action.payload
    },
    setPageStep: (state, action: PayloadAction<PageSteps>) => {
      state.pageStep = action.payload
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setUser: (state, action: PayloadAction<UserSimple>) => {
      state.user = action.payload
    },
    setLoading: (state, action: PayloadAction<LoadingType>) => {
      state.loading = action.payload
    },
    reset: () => initialState
  }
})

export const {
  setSubscriptionId,
  setCid,
  setPageStep,
  setToken,
  setUser,
  setLoading,
  reset
} = steps.actions

export const selectSteps = (state: RootState) => state.steps

export default steps.reducer
