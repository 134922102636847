import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import env from 'settings/env'
import { selectCrmEvents, setEvent } from 'store/modules/CrmEvents.store'
import { selectSteps } from 'store/modules/Steps.store'
import { selectTheme, setThemeLocalstorage } from 'store/modules/Theme.store'
import { whiteLabelType } from 'types'
import { getWhitelabelLocalStorage } from 'utils/plans/helpers'
import { ga4PageView } from './ga4'
import { initializeTagManager } from './gtag'
import { initializePixel, pixelPageView } from './pixel'
import { sendOzyPageViewEvent } from 'services/api/ozymandias'

export const StartTrackingEvents = () => {
  const [loadedTheme, setLoadedTheme] = useState(false)
  const [stopEventHandle, setStopEventHandle] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const ENVIRONMENT = env.ENVIRONMENT

  useEffect(() => {
    const product = searchParams.get('whitelabel')
    let themeName = product
    const label: whiteLabelType = getWhitelabelLocalStorage() as whiteLabelType
    if (product) {
      themeName = product
    } else if (label) {
      themeName = label
    }
    dispatch(setThemeLocalstorage(themeName || 'leiturinha'))
    setLoadedTheme(true)
  }, [])
  const { theme } = useAppSelector(selectTheme)
  const { token } = useAppSelector(selectSteps)
  const { events } = useAppSelector(selectCrmEvents)
  const location = useLocation()

  useEffect(() => {
    if (loadedTheme) {
      switch (location.pathname) {
        case theme.crm.plans.path:
          dispatch(setEvent(theme.crm.plans.page))
          break
        case theme.crm.kids.path:
          dispatch(setEvent(theme.crm.kids.page))
          break
        case theme.crm.sign_in.path:
          dispatch(setEvent(theme.crm.sign_in.page))
          break
        case theme.crm.payment.path:
          dispatch(setEvent(theme.crm.payment.page))
          break
        case theme.crm.success.path:
          dispatch(setEvent(theme.crm.success.page))
          break
      }
    }
    if (loadedTheme) {
      // ugaPageView(location)
      pixelPageView(location)
      // ga4PageView(location)
    }
  }, [loadedTheme, theme, location.pathname])

  useEffect(() => {
    if (localStorage.getItem('cookies_consent') === 'true') {
      if (events && events.length > 0) {
        events?.map((page) => {
          sendOzyPageViewEvent(page, token)
        })
        dispatch(setEvent(null))
      }
    }
  }, [events])

  // if (loadedTheme && !stopEventHandle && ENVIRONMENT === 'production') {
  if (loadedTheme && !stopEventHandle) {
    if (theme.tracking.gtm !== null && theme.tracking.gtm !== undefined) {
      initializeTagManager(theme.tracking.gtm)
    }
    if (theme.tracking.pixel !== null && theme.tracking.pixel !== undefined) {
      initializePixel(theme.tracking.pixel)
    }
    // if (theme.tracking.ga !== null && theme.tracking.ga !== undefined) {
    //   initializeUniversalAnalytics(theme.tracking.ga, location)
    // }
    // if (theme.tracking.ga4 !== null && theme.tracking.ga4 !== undefined) {
    //   initializeGA4(theme.tracking.ga4, location)
    // }
    setStopEventHandle(true)
  }

  return <></>
}
