import axios from 'axios'
import env from 'settings/env'

const API_CRM_URL = env.API_URL

export async function sendOzyEvent(
  page: string,
  email?: string,
  token?: string | null
): Promise<any> {
  const options = {
    method: 'POST',
    url: API_CRM_URL + `/event/pageview`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      page: page,
      email: email
    }
  }
  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await axios.request(options)
  return response.data.body
}

export async function sendOzyPageViewEvent(
  page: string,
  token?: string | null
): Promise<any> {
  const options = {
    method: 'POST',
    url: API_CRM_URL + `/event/pageview`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      page: page
    }
  }

  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await axios.request(options)
  return response.data.body
}
