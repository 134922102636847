import routes from 'routes'
import { v4 as uuidv4 } from 'uuid'

import ReactPixel from 'react-facebook-pixel'

export function initializePixel(themeId: string) {
  ReactPixel.init(themeId)
}

export function pixelPageView(location) {
  switch (location.pathname) {
    case routes.signature.kids:
      ReactPixel.track('InitiateCheckout', {
        eventID: `InitiateCheckout_${uuidv4()}`
      })
      break
    case routes.signature.registration:
      ReactPixel.track('AddToCart', {
        eventID: `AddToCart_${uuidv4()}`
      })
      break
    case routes.signature.login:
      ReactPixel.fbq('track', 'AddToCart', {
        eventID: `AddToCart_${uuidv4()}`
      })
      break
    case routes.signature.payment:
      ReactPixel.fbq('track', 'AddPaymentInfo', {
        eventID: `AddPaymentInfo_${uuidv4()}`
      })
      break
    default:
      ReactPixel.pageView()
      break
  }
}

export function facebookConversionData(themeId: string, total: number) {
  ReactPixel.fbq('track', 'Purchase', {
    eventID: `Purchase_${uuidv4()}`,
    currency: 'BRL',
    value: total
  })
}
