import React, { lazy, Suspense } from 'react'
import routes from 'routes'
import Page from 'components/Page'
import Loading from 'pages/Loading'
import PurchasePending from 'pages/Purchase/Pending'

const HomeSteps = lazy(() => import('pages/HomeSteps'))
const NotFound = lazy(() => import('pages/NotFound'))
const Plan = lazy(() => import('pages/Plan'))
const Kids = lazy(() => import('pages/Kids'))
const Categories = lazy(() => import('pages/Categories'))
const SignUp = lazy(() => import('pages/SignUp'))
const Login = lazy(() => import('pages/Login'))
const Payment = lazy(() => import('pages/PaymentV2'))
const ForgotPassword = lazy(() => import('pages/ForgotPassword'))
const PurchaseConfirmation = lazy(() => import('pages/Purchase/Confirmation'))
const PurchaseError = lazy(() => import('pages/Purchase/Error'))
const PurchasePix = lazy(() => import('pages/Purchase/Pix'))
const Reset = lazy(() => import('pages/Reset'))
import { Routes, Route, Navigate } from 'react-router-dom'
import ResetToken from 'pages/ResetToken'
import { useAppDispatch } from 'hooks/useStore'

export default function Public() {
  return (
    <Routes>
      <Route
        path={routes.home}
        element={
          <Page>
            <Suspense fallback={<Loading isTextShow={false} />}>
              <HomeSteps isHeadingMode1={true}>
                <Plan />
              </HomeSteps>
            </Suspense>
          </Page>
        }
      />

      <Route
        path={routes.reset}
        element={
          <Page>
            <Suspense fallback={<Loading isTextShow={false} />}>
              <Reset />
            </Suspense>
          </Page>
        }
      />

      <Route
        path={routes.resetToken}
        element={
          <Page>
            <Suspense fallback={<Loading isTextShow={false} />}>
              <ResetToken />
            </Suspense>
          </Page>
        }
      />

      <Route
        path={routes.signature.kids}
        element={
          <Page>
            <Suspense fallback={<Loading isTextShow={false} />}>
              <HomeSteps>
                <Kids />
              </HomeSteps>
            </Suspense>
          </Page>
        }
      />

      <Route
        path={routes.signature.categories}
        element={
          <Page>
            <Suspense fallback={<Loading isTextShow={false} />}>
              <HomeSteps>
                <Categories />
              </HomeSteps>
            </Suspense>
          </Page>
        }
      />

      <Route
        path={routes.signature.registration}
        element={
          <Page>
            <Suspense fallback={<Loading isTextShow={false} />}>
              <HomeSteps>
                <SignUp />
              </HomeSteps>
            </Suspense>
          </Page>
        }
      />

      <Route
        path={routes.signature.login}
        element={
          <Page>
            <Suspense fallback={<Loading isTextShow={false} />}>
              <HomeSteps>
                <Login />
              </HomeSteps>
            </Suspense>
          </Page>
        }
      />

      <Route
        path={routes.signature.forgotPassword}
        element={
          <Page>
            <Suspense fallback={<Loading isTextShow={false} />}>
              <HomeSteps>
                <ForgotPassword />
              </HomeSteps>
            </Suspense>
          </Page>
        }
      />

      <Route path="*" element={<Navigate to={routes.home} replace />} />
    </Routes>
  )
}
