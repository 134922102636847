import { useAppSelector } from 'hooks/useStore'
import { selectSteps } from 'store/modules/Steps.store'
import Loading from 'pages/Loading'

type PageProps = {
  children: React.ReactNode
}

export default function Page({ children }: PageProps) {
  const { loading } = useAppSelector(selectSteps)

  return (
    <>
      {loading?.status && <Loading message={loading.message} />}
      <main className="flex-1">{children}</main>
    </>
  )
}
