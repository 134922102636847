import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {

  CryptoPaymentResponse,
  PaymentAdyen,
  PaymentData,
  PaymentMethod,
  PaymentResponse,
  PaymentGateway
} from 'types'

type PaymentState = {
  payment: PaymentData
  paymentGateway: PaymentGateway | string
  paymentMethod: PaymentMethod
  paymentResponse?: PaymentResponse
  paymentCryptoResponse?: CryptoPaymentResponse
}

const initialState: PaymentState = {
  payment: {},
  paymentMethod: PaymentMethod.CreditCard,
  paymentGateway: '',
  paymentResponse: undefined,
  paymentCryptoResponse: undefined,
  paymentAdyen: {}

}

const payment = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPayment: (state, action: PayloadAction<PaymentData>) => {
      state.payment = action.payload
    },
    setPaymentGateway: (state, action: PayloadAction<PaymentGateway>) => {
      state.paymentGateway = action.payload
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload
    },
    setPaymentResponse: (state, action: PayloadAction<PaymentResponse>) => {
      state.paymentResponse = action.payload
    },
    setCPF: (state, action: PayloadAction<PaymentAdyen>) => {
      state.paymentAdyen = action.payload
    },
    setPaymentCryptoResponse: (
      state,
      action: PayloadAction<CryptoPaymentResponse>
    ) => {
      state.paymentCryptoResponse = action.payload
    },
    reset: () => initialState
  }
})

export const {
  setPayment,
  setPaymentMethod,
  setPaymentGateway,
  setPaymentResponse,
  setCPF,
  setPaymentCryptoResponse,
  reset
} = payment.actions

export const selectPayment = (state: RootState) => state.payment

export default payment.reducer
