import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { DeliveryAddress } from 'types'

type AddressState = {
  deliveryAddress: DeliveryAddress
}

const initialState: AddressState = {
  deliveryAddress: {}
}

const address = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setDeliveryAddress: (state, action: PayloadAction<DeliveryAddress>) => {
      state.deliveryAddress = action.payload
    },
    reset: () => initialState
  }
})

export const { setDeliveryAddress, reset } = address.actions

export const selectAddress = (state: RootState) => state.address

export default address.reducer
