export type IconProps = {
  width?: string
  height?: string
  color?: string
}

export const CheckCheck: React.FC<IconProps> = ({
  width = '15',
  height = '6',
  color = '#FFF'
}) => {
  return (
    <svg
      data-testid="icon"
      width={width}
      height={height}
      viewBox="0 0 15 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.07687 0.3125L2.9675 4.42188L1.4675 2.90625C1.38937 2.84375 1.26437 2.84375 1.20187 2.90625L0.74875 3.35938C0.68625 3.42188 0.68625 3.54688 0.74875 3.625L2.8425 5.70312C2.92062 5.78125 3.03 5.78125 3.10812 5.70312L7.79562 1.01562C7.85812 0.953125 7.85812 0.828125 7.79562 0.75L7.3425 0.3125C7.28 0.234375 7.155 0.234375 7.07687 0.3125ZM13.7969 0.3125L9.6875 4.42188L8.1875 2.90625C8.10938 2.84375 7.98438 2.84375 7.92188 2.90625L7.46875 3.35938C7.40625 3.42188 7.40625 3.54688 7.46875 3.625L9.5625 5.70312C9.64062 5.78125 9.75 5.78125 9.82812 5.70312L14.5156 1.01562C14.5781 0.953125 14.5781 0.828125 14.5156 0.75L14.0625 0.3125C14 0.234375 13.875 0.234375 13.7969 0.3125Z"
        fill={color}
      />
    </svg>
  )
}
