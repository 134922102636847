import { whatAge } from 'utils/date'
import { genderToString, yearsPlural } from 'utils/format'
import { Child } from 'types'

export default function toKidsFormatedString(kids: Child[]) {
  return kids.map((item) => {
    const childAge =
      item.birthday && whatAge(new Date(item.birthday)) >= 1
        ? whatAge(new Date(item.birthday))
        : 0

    return `${item.name}, ${childAge} ${yearsPlural(
      childAge
    )} • ${genderToString(item.gender)}`
  })
}
