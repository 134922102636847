export default {
  meta: {
    title: '',
    description: ''
  },
  content: {
    title: 'Page not found',
    headline: '404 error',
    description: "Sorry, we cound't find the page you're looking for.",
    actions: {
      navigate: 'Go back home'
    }
  }
} as const
