import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { I18nextProvider } from 'react-i18next'
import { store, persistor } from 'store'

import i18n from 'settings/i18n'
import { PaymentProvider } from 'hooks/usePayment'
import { NotificationToast } from 'components/NotificationToast'
import { MagicLinkProvider } from 'hooks/useMagicLink'

type ProvidersProps = {
  children: React.ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true
    }
  }
})

export default function Providers({ children }: ProvidersProps) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Ld9YOkjAAAAAG1ZpPPJ4LoyeqSfex_lG0vxfcPo"
      scriptProps={{
        async: true, // optional, default to false,
        appendTo: 'head' // optional, default to "head", can be "head" or "body",
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <MagicLinkProvider>
                  <PaymentProvider>{children}</PaymentProvider>
                </MagicLinkProvider>
                <NotificationToast />
              </QueryClientProvider>
            </BrowserRouter>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </GoogleReCaptchaProvider>
  )
}
