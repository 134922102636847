import axios from 'axios'
import endpoints from 'services/api/modules/Checkout/Checkout.endpoints'
import env from 'settings/env'

type Children = {
  name: string
  birthday?: Date | string
  gender: string
  type?: string
  didatic_age?: Date | string
  category?: string
  id?: number
}

export interface ChangeChildrenRequest {
  token: string
  orderId: string | number
  payload: {
    children: Children[]
  }
}

export interface ChangeChildrenResponse {
  status: number
  code: number
  body: Children[]
}

export interface ChangeChildrenResponseError {
  code: number
  status: number
  error: string
}

export async function changeChildren({
  token,
  orderId,
  payload
}: ChangeChildrenRequest): Promise<
  ChangeChildrenResponse | ChangeChildrenResponseError
> {
  const orderIdValue = orderId.toString()

  const method = endpoints.POST.children.method
  const url = `${env.API_URL}${endpoints.POST.children.uri.replace(
    ':id',
    orderIdValue
  )}`
  const data = { ...payload }
  const headers = {
    ...endpoints.POST.children.headers,
    Authorization: `Bearer ${token}`
  }

  const options = {
    method,
    url,
    headers,
    data
  }

  const response = await axios.request(options)
  return response.data
}
