import { useAppSelector } from 'hooks/useStore'
import { selectTheme } from 'store/modules/Theme.store'
import { getImage } from 'utils/images'

export function Banner() {
  const { theme } = useAppSelector(selectTheme)
  return (
    <div className="hidden md:flex items-center justify-center sm:block w-[430px]">
      <img src={getImage(theme.banner.img)} alt="Characters Image" />
    </div>
  )
}
