import { PROMOTION_BIN as promotionBins } from 'data/PromotionBin'

export type BrandFlagCardType =
  | 'unknown'
  | 'mastercard'
  | 'visa'
  | 'amex'
  | 'elo'

export function getCreditCardBrandFlag(
  accountNumber: string
): BrandFlagCardType {
  //start without knowing the credit card type
  let result: BrandFlagCardType = 'unknown'

  //first check for MasterCard
  if (/^5[1-5]/.test(accountNumber)) {
    result = 'mastercard'
  }

  //then check for Visa
  else if (/^4/.test(accountNumber)) {
    result = 'visa'
  }

  //then check for AmEx
  else if (/^3[47]/.test(accountNumber)) {
    result = 'amex'
  }

  //then check for Elo
  if (
    accountNumber == '4011' ||
    accountNumber == '4312' ||
    accountNumber == '4389' ||
    accountNumber == '4514' ||
    accountNumber == '4573' ||
    accountNumber == '4576' ||
    accountNumber == '5041' ||
    accountNumber == '5066' ||
    accountNumber == '5067' ||
    accountNumber == '5090' ||
    accountNumber == '5099' ||
    accountNumber == '6277' ||
    accountNumber == '6362' ||
    accountNumber == '6363' ||
    accountNumber == '6500' ||
    accountNumber == '6504' ||
    accountNumber == '6505' ||
    accountNumber == '6507' ||
    accountNumber == '6509' ||
    accountNumber == '6516' ||
    accountNumber == '6550'
  ) {
    result = 'elo'
  }

  return result
}

export function toBase64(str: string): string {
  const encoded = btoa(str)
  return encoded
}

export function binPromotion(bin: string): string | null {
  let promotion = ''

  if (promotionBins?.length > 0) {
    promotionBins.map((promotionBin) => {
      if (bin === promotionBin.bin && !!promotionBin.promotion) {
        promotion = promotionBin.promotion
      }
    })
  }

  return promotion === '' ? null : promotion
}
