import { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import routes from 'routes'
import Page from 'components/Page'
import Loading from 'pages/Loading'
import PurchasePending from 'pages/Purchase/Pending'
import ResetToken from 'pages/ResetToken'

const HomeSteps = lazy(() => import('pages/HomeSteps'))
const Plan = lazy(() => import('pages/Plan'))
const Kids = lazy(() => import('pages/Kids'))
const Categories = lazy(() => import('pages/Categories'))
const Address = lazy(() => import('pages/Address'))
const Payment = lazy(() => import('pages/PaymentV2'))
const PaymentAdyen = lazy(() => import('pages/PaymentAdyen'))
const PurchaseCypto = lazy(() => import('pages/Purchase/Crypto'))
const PurchaseConfirmation = lazy(() => import('pages/Purchase/Confirmation'))
const PurchaseError = lazy(() => import('pages/Purchase/Error'))
const PurchasePix = lazy(() => import('pages/Purchase/Pix'))
const Reset = lazy(() => import('pages/Reset'))

export default function Private() {
  return (
    <>
      <Routes>
        <Route
          path={routes.reset}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <Reset />
              </Suspense>
            </Page>
          }
        />

        <Route
          path={routes.resetToken}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <ResetToken />
              </Suspense>
            </Page>
          }
        />

        <Route
          path={routes.home}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps isHeadingMode1={true}>
                  <Plan />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />

        <Route
          path={routes.signature.kids}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps>
                  <Kids />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />

        <Route
          path={routes.signature.categories}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps>
                  <Categories />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />

        <Route
          path={routes.signature.address}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps>
                  <Address />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />

        <Route
          path={routes.signature.payment}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps>
                  <PaymentAdyen />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />

        <Route
          path={routes.signature.purchaseConfirmation}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps>
                  <PurchaseConfirmation />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />
        <Route
          path={routes.signature.purchasePix}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps isHeadingMode1={true}>
                  <PurchasePix />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />
        <Route
          path={routes.signature.purchaseCrypto}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps>
                  <PurchaseCypto />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />
        <Route
          path={routes.signature.purchasePending}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps isHeadingMode1={true}>
                  <PurchasePending />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />
        <Route
          path={routes.signature.purchaseError}
          element={
            <Page>
              <Suspense fallback={<Loading isTextShow={false} />}>
                <HomeSteps>
                  <PurchaseError />
                </HomeSteps>
              </Suspense>
            </Page>
          }
        />

        <Route
          path="*"
          element={<Navigate to={routes.signature.address} replace />}
        />
      </Routes>
    </>
  )
}
