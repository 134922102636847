import axios from 'axios'
import endpoints from 'services/api/modules/Checkout/Checkout.endpoints'
import env from 'settings/env'
import { Child, DeliveryAddress } from 'types'
import { Plan } from 'types/Responses/DiscountResponse'

export interface ChangePlanRequest {
  token: string
  orderId: string | number
  payload: {
    plan_sku: string
  }
}

export interface ChangePlanResponse {
  status: number
  code: number
  body: {
    id: number | string
    status: string
    browser?: null
    created_at: string | Date
    updated_at: string | Date
    children: Child[]
    plan: Plan
    address: DeliveryAddress
  }
}

export interface ChangePlanResponseError {
  code: number
  error: string
}

export async function changePlan({
  token,
  orderId,
  payload
}: ChangePlanRequest): Promise<ChangePlanResponse | ChangePlanResponseError> {
  const orderIdValue = orderId.toString()

  const method = endpoints.POST.plan.method
  const url = `${env.API_URL}${endpoints.POST.plan.uri.replace(
    ':id',
    orderIdValue
  )}`
  const data = { ...payload }
  const headers = {
    ...endpoints.POST.plan.headers,
    Authorization: `Bearer ${token}`
  }

  const options = {
    method,
    url,
    headers,
    data
  }

  const response = await axios.request(options)
  return response.data
}
