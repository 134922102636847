import CheckCicleIcon from '@heroicons/react/solid/CheckCircleIcon'

type TDemandItem = {
  iconColor?: string
  item: string
}

export default function DemandItem({
  iconColor = 'darkGrey',
  item
}: TDemandItem) {
  const style = `text-${iconColor}`

  return (
    <div className="flex gap-1">
      <CheckCicleIcon className={`w-3 ${style}`} />
      <p className="w-full text-xs font-bold text-darkGrey">{item}</p>
    </div>
  )
}
