import { createContext, ReactNode, useContext, useState } from 'react'

import axios from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useSession } from 'react-use-session'
import { useForm, UseFormRegister, FieldErrorsImpl } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import valid from 'card-validator'
import { isCPF } from 'brazilian-values'

import { selectSteps } from 'store/modules/Steps.store'
import { setPayment, selectPayment } from 'store/modules/Payment.store'
import { PaymentData, PixResponseData } from 'types'

import { sendPayment, sendVindiPayment } from 'services/api/payment'
import { getCreditCardBrandFlag } from 'utils/payments'
import { selectUrlParams } from 'store/modules/UrlParams.store'
import { selectDiscount } from 'store/modules/Discount.store'

type ResponseBody = { message?: string | PixResponseData; status?: string }

type PaymentContextData = {
  onSubmit: () => Promise<void>
  control: any
  watchFields: any
  register: UseFormRegister<PaymentData>
  errors: FieldErrorsImpl<PaymentData>
  loading: boolean
  paymentResponse: ResponseBody
  resetPayment: () => void
  selectedBrandFlag: string
}

export const PaymentContext = createContext({} as PaymentContextData)
PaymentContext.displayName = 'Payment Context'

export const PaymentProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [paymentResponse, setPaymentResponse] = useState<ResponseBody>({})
  const dispatch = useAppDispatch()
  const { paymentMethod } = useAppSelector(selectPayment)
  const { promoItems } = useAppSelector(selectUrlParams)
  const { pinCode, promotion } = useAppSelector(selectDiscount)

  const { orderId, token } = useAppSelector(selectSteps)
  const { session } = useSession('session-leiturinha', false)
  //const token = session ? session.token : ''
  function resetPayment() {
    setPaymentResponse({})
  }
  const validationSchema = Yup.object().shape({
    cpf: Yup.string().test('test-cpf', 'Número de cpf inválido', (value) =>
      isCPF(value!)
    ),
    pin: Yup.string()
  })

  if (paymentMethod === 'credit_card') {
    validationSchema.shape({
      card_number: Yup.string()
        .test(
          'test-number',
          'Número de cartão de crédito inválido',
          (value) => valid.number(value).isValid
        )
        .required(),
      holder_name: Yup.string().required('Preencha este campo'),
      card_expiration: Yup.string()
        .test(
          'test-number',
          'Data de validade inválida',
          (value) => valid.expirationDate(value).isValid
        )
        .required(),
      card_cvv: Yup.string()
        .test(
          'test-number',
          'Código de segurança inválido',
          (value) => valid.cvv(value).isValid
        )
        .required(),
      cpf: Yup.string().test('test-cpf', 'Número de cpf inválido', (value) =>
        isCPF(value!)
      ),
      pin: Yup.string()
    })
  }

  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<PaymentData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const watchFields = watch([
    'card_number',
    'holder_name',
    'card_expiration',
    'card_cvv',
    'cpf',
    'pin'
  ])

  const selectedBrandFlag = getCreditCardBrandFlag(watchFields[0] as string)

  const onSubmit = handleSubmit((data: PaymentData) => {
    setLoading(true)
    dispatch(setPayment(data))
    const cpf = data.cpf

    if (paymentMethod === 'credit_card') {
      sendVindiPayment(data).then((gateway_token) => {
        sendPayment(
          paymentMethod,
          orderId,
          token,
          data.cpf,
          gateway_token,
          pinCode,
          promoItems,
          data.ambassador_code,
          promotion
        ).then((response) => {
          setPaymentResponse(response)
        })

        //handlePaymentCredit(response, cpf!)
      })
    }
    if (paymentMethod === 'pix') {
      sendPayment(
        paymentMethod,
        orderId,
        token,
        data.cpf,
        null,
        pinCode,
        promoItems,
        data.ambassador_code,
        promotion
      ).then((response) => {
        setPaymentResponse(response)
      })
    }
  })

  return (
    <PaymentContext.Provider
      value={{
        paymentResponse,
        resetPayment,
        control,
        watchFields,
        loading,
        register,
        onSubmit,
        errors,
        selectedBrandFlag
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

export const usePayment = () => {
  const context = useContext(PaymentContext)
  if (!context) {
    throw new Error(
      'Para usar o usePayment, é obrigatório o usuário do Provider'
    )
  }
  return context
}
