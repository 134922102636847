import { useEffect } from 'react'
import {
  selectUrlParams,
  setCreated,
  setPromoItems
} from 'store/modules/UrlParams.store'
import { CountDiffInMinutes } from 'utils/date/CountDiffInMonth/CountDiffInMonth'
import env from 'settings/env'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useSession } from 'react-use-session'
import Private from './Private'
import Public from './Public'
import { selectSteps } from 'store/modules/Steps.store'

export default function Router() {
  const dispatch = useAppDispatch()
  const { createdAt } = useAppSelector(selectUrlParams)
  const expiresTime = env.EXPIRES_TIME

  const { session } = useSession('session-leiturinha', false)

  const { token, user } = useAppSelector(selectSteps)

  const hasSession = !!token && !!user

  useEffect(() => {
    if (createdAt) {
      const expires = CountDiffInMinutes(new Date(createdAt))
      if (expires > expiresTime) {
        dispatch(setPromoItems(null))
        dispatch(setCreated(null))
      }
    }
  }, [createdAt])

  return hasSession ? <Private /> : <Public />
}
