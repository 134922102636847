export default {
  home: '/',
  reset: '/reset',
  resetToken: '/logout',
  signature: {
    kids: '/subscribe/kids',
    categories: '/subscribe/category',
    registration: '/subscribe/signup',
    login: '/subscribe/signin',
    address: '/subscribe/address',
    payment: '/subscribe/payment',
    purchaseConfirmation: '/subscribe/payment/status/success',
    purchaseError: '/subscribe/payment/status/error',
    purchasePix: '/subscribe/payment/status/pix',
    purchaseCrypto: '/subscribe/payment/status/crypto',
    purchasePending: '/subscribe/payment/status/pending',
    forgotPassword: '/subscribe/recover-password'
  }
} as const
