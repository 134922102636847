import axios from 'axios'
import env from 'settings/env'

const API_PROFILE_URL = env.API_URL + '/profile'

export async function getProfileUser(token: string) {
  const options = {
    method: 'GET',
    url: API_PROFILE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const response = await axios.request(options)
  return response
}
