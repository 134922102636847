import axios, { AxiosResponse } from 'axios'
import {
  CEPResponse,
  CheckoutGetResponse,
  CheckoutResponse,
  DeliveryAddress,
  OrderRequest,
  PromoitemsData
} from 'types'
import env from 'settings/env'
import { UrlParams } from 'types/url_params'
import checkoutEndpoints from './modules/Checkout/Checkout.endpoints'
import api from '.'

const API_CHECKOUT_URL = env.API_URL + '/checkout'

async function verifyOrder(
  token: string,
  project: string
): Promise<CheckoutGetResponse> {
  const options = {
    method: 'GET',
    url: `/checkout?project=${project}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const response = await api.get(`/checkout?project=${project}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  return response.data.body
}

async function createOrder(
  data: OrderRequest,
  token: string
): Promise<CheckoutResponse> {
  const options = {
    method: 'POST',
    url: '/checkout',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: { ...data }
  }

  const response = await api.request(options)

  // const response = await axios.request(options)
  return response.data.body
}

async function sendAddress(
  orderId: number,
  data: DeliveryAddress,
  token: string
) {
  const options = {
    method: 'POST',
    url: `/checkout/${orderId}/address`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {
      city: data.city,
      complement: data.complement,
      neighborhood: data.neighborhood,
      number: data.number,
      reference: data.reference,
      state: data.state,
      street: data.street,
      zipcode: data.zipcode,
      recipient: data.recipient
    }
  }
  const response = await api.request(options)
  return response
}

async function sendMediaSource(
  orderId: number,
  data: UrlParams,
  token: string
) {
  const options = {
    method: 'POST',
    url: `/checkout/${orderId}/media-sources`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {
      src: data.src,
      utm_source: data.utmSource,
      utm_medium: data.utmMedium,
      utm_content: data.utmContent,
      utm_campaign: data.utmCampaign,
      campaign_id: data.campaignId,
      adset_id: data.adsetId,
      ad_id: data.adId
    }
  }

  const response = await api.request(options)
  return response
}

async function checkCEP(zipcode: string): Promise<CEPResponse> {
  const API_ZIPCODE_CHECK = env.API_ZIPCODE_CHECK + `/${zipcode}/country/BR`
  const options = {
    url: API_ZIPCODE_CHECK,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const response = await axios.get(API_ZIPCODE_CHECK)
  return response.data
}

async function sendValidatePromoitems(data: PromoitemsData): Promise<string> {
  const options = {
    method: checkoutEndpoints.POST.validatePromoitems.method,
    url: `${checkoutEndpoints.POST.validatePromoitems.uri.replace(
      ':id',
      data.order_id.toString()
    )}`,
    headers: {
      ...checkoutEndpoints.POST.validatePromoitems.headers,
      Authorization: `Bearer ${data.token}`
    },
    data: {
      promoitems: data.promoitems,
      plan_sku: data.plan_sku
    }
  }

  const response = await api.request(options)
  return response.data.body
}

export {
  verifyOrder,
  createOrder,
  sendAddress,
  sendMediaSource,
  checkCEP,
  sendValidatePromoitems
}
