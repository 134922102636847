import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import * as storage from 'services/storage'
import { themeFlap, themeLeiturinha } from 'data/Theme'
import { ThemeState } from 'types'
import { getWhitelabelLocalStorage } from 'utils/plans/helpers'

const initialState: ThemeState = {
  theme: themeLeiturinha
}

const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeLocalstorage: (state, action: PayloadAction<string>) => {
      const whitelabel = storage.set('@pk:whitelabel', action.payload)
      state.theme = whitelabel === 'flap' ? themeFlap : themeLeiturinha
    },
    getThemeLocalstorage: (state) => {
      const formatedWhitelabel = getWhitelabelLocalStorage()
      state.theme = formatedWhitelabel === 'flap' ? themeFlap : themeLeiturinha
    },
    clearLocalstorage: (state) => {
      storage.clear()
    },
    reset: () => initialState
  }
})

export const { getThemeLocalstorage, setThemeLocalstorage, reset } =
  theme.actions

export const selectTheme = (state: RootState) => state.theme

export default theme.reducer
