import axios from 'axios'
import env from 'settings/env'

const API_SUBSCRIPTION_URL = env.API_URL + '/subscription'

export type IGetSubscriptionFromId = {
  token: string
  subscriptionId: number
}

export async function getSubscriptionFromId(data: IGetSubscriptionFromId) {
  const options = {
    method: 'GET',
    url: API_SUBSCRIPTION_URL + `/${data.subscriptionId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.token}`
    }
  }

  const response = await axios.request(options)
  return response
}
