import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { AmbassadorResponse } from 'types/Responses/AmbassadorResponse'

type AmbassadorState = {
  ambassadorCode?: string
  ambassadorResponse?: AmbassadorResponse
}

const initialState: AmbassadorState = {
  ambassadorCode: undefined,
  ambassadorResponse: undefined
}

const Ambassador = createSlice({
  name: 'ambassador',
  initialState,
  reducers: {
    setAmbassadorCode: (state, action: PayloadAction<string | undefined>) => {
      state.ambassadorCode = action.payload
    },
    setAmbassadorResponse: (
      state,
      action: PayloadAction<AmbassadorResponse | undefined>
    ) => {
      state.ambassadorResponse = action.payload
    },
    reset: () => initialState
  }
})

export const { setAmbassadorResponse, setAmbassadorCode, reset } =
  Ambassador.actions

export const selectAmbassador = (state: RootState) => state.ambassador

export default Ambassador.reducer
