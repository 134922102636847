export type IconProps = {
  width?: string
  height?: string
  color?: string
  className?: string
}

export const CreditCardElo: React.FC<IconProps> = ({
  width = '70',
  height = '46',
  color = '#FFF',
  className = ''
}) => {
  return (
    <svg
      data-testid="icon_CreditCardElo"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 750 471"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="elo" fill={color} fillRule="nonzero">
          <path
            d="M52.8846154,28.2035928 C39.6002608,28.2035928 28.8461538,38.7181472 28.8461538,51.7065868 L28.8461538,419.293413 C28.8461538,432.281853 39.6002608,442.796407 52.8846154,442.796407 L697.115385,442.796407 C710.399739,442.796407 721.153846,432.281853 721.153846,419.293413 L721.153846,51.7065868 C721.153846,38.7181472 710.399739,28.2035928 697.115385,28.2035928 L52.8846154,28.2035928 Z M52.8846154,0 L697.115385,0 C726.33103,0 750,23.141733 750,51.7065868 L750,419.293413 C750,447.858267 726.33103,471 697.115385,471 L52.8846154,471 C23.66897,471 0,447.858267 0,419.293413 L0,51.7065868 C0,23.141733 23.66897,0 52.8846154,0 Z"
            id="Rectangle-1"
          ></path>
          <g id="Group-2" transform="translate(77.000000, 126.000000)">
            <path
              d="M89.0191693,48.1750799 C95.3980831,46.0805112 102.253035,44.8428115 109.39361,44.8428115 C140.43131,44.8428115 166.327796,66.9309904 172.230671,96.1597444 L216.216613,87.2102236 C206.124601,37.4166134 162.138658,0 109.39361,0 C97.3022364,0 85.686901,1.99936102 74.8332268,5.6172524 L89.0191693,48.1750799 Z M37.1309904,190.700958 L66.8357827,157.092652 C53.0695821,144.90733 45.1987265,127.397277 45.2236422,109.01278 C45.2236422,89.8760383 53.6019169,72.7386581 66.8357827,61.028115 L37.1309904,27.4198083 C14.5667732,47.4134185 0.380830671,76.5469649 0.380830671,109.01278 C0.380830671,141.478594 14.5667732,170.707348 37.1309904,190.700958 Z M172.135463,121.865815 C166.13738,151.094569 140.336102,173.08754 109.298403,173.08754 C102.157827,173.08754 95.3028754,171.945048 88.9239617,169.755272 L74.6428115,212.313099 C85.4964856,215.93099 97.1118211,217.930351 109.203195,217.930351 C161.948243,217.930351 205.838978,180.513738 216.026198,130.815335 L172.135463,121.865815 Z"
              id="Shape"
            ></path>
            <g id="Group" transform="translate(268.485623, 26.658147)">
              <path
                d="M94.0651757,125.293291 C86.829393,132.338658 76.9277955,136.623003 66.0741214,136.527796 C58.6479233,136.432588 51.6977636,134.147604 45.8900958,130.434505 L31.3233227,153.665176 C41.3201278,159.948882 53.0306709,163.661981 65.6932907,163.852396 C84.1635783,164.138019 100.920128,156.902236 113.201917,144.90607 L94.0651757,125.293291 Z M67.6926518,30.8472843 C31.0376997,30.2760383 0.761661342,59.6 0.190415335,96.3501597 C5.8730798e-14,110.155272 3.99872204,123.008307 10.9488818,133.671565 L131.386581,82.1642173 C124.626837,53.1258786 98.8255591,31.3233227 67.6926518,30.8472843 Z M27.800639,101.491374 C27.6102236,99.9680511 27.515016,98.3495208 27.6102236,96.7309904 C27.9910543,75.1188498 45.7948882,57.7910543 67.4070288,58.171885 C79.2127796,58.3623003 89.5904153,63.6939297 96.7309904,72.0722045 L27.800639,101.491374 Z M169.184026,0.85686901 L169.184026,129.292013 L191.46262,138.527157 L180.894569,163.852396 L158.901597,154.71246 C153.950799,152.522684 150.61853,149.285623 148.047923,145.572524 C145.572524,141.764217 143.763578,136.623003 143.763578,129.672843 L143.763578,0.85686901 L169.184026,0.85686901 Z"
                id="Shape"
              ></path>
              <path
                d="M249.539297,60.171246 C253.442812,58.8383387 257.631949,58.171885 261.916294,58.171885 C280.86262,58.171885 296.667093,71.5961661 300.284984,89.4952077 L327.133546,84.0683706 C320.945048,53.6971246 294.096486,30.8472843 261.916294,30.8472843 C254.585304,30.8472843 247.444728,32.084984 240.875399,34.2747604 L249.539297,60.171246 Z M217.930351,147.191054 L236.115016,126.721406 C228.022364,119.580831 222.88115,109.107987 222.88115,97.3974441 C222.88115,85.686901 228.022364,75.3092652 236.115016,68.0734824 L217.930351,47.6038339 C204.12524,59.7904153 195.461342,77.5942492 195.461342,97.3974441 C195.461342,117.200639 204.12524,135.004473 217.930351,147.191054 Z M300.284984,105.204473 C296.667093,123.008307 280.86262,136.527796 261.916294,136.527796 C257.536741,136.527796 253.442812,135.861342 249.539297,134.528435 L240.875399,160.520128 C247.539936,162.709904 254.585304,163.947604 262.011502,163.947604 C294.191693,163.947604 321.040256,141.097764 327.228754,110.726518 L300.284984,105.204473 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
