import { getImage } from 'utils/images'
import { CategoryData } from 'types'

// ageMin and ageMax are in months

export const CategoriesList: CategoryData[] = [
  {
    category: 'A1',
    image: getImage('leitorDeBerco'),
    title: 'Leitor de Berço',
    suggestion: 'Sugerido para 0 a 11 meses',
    description:
      'Fortalecimento de vínculos afetivos, por meio da leitura compartilhada, do estímulo à coordenação motora e às percepções táteis.',
    ageMin: 0,
    ageMax: 11
  },
  {
    category: 'A2',
    image: getImage('leitorBrincante'),
    title: 'Leitor Brincante',
    suggestion: 'Sugerido para 1 a 2 anos e 11 messes',
    description:
      'Estímulo ao vocabulário e conceitos básicos como cores e números. Exercício da motricidade fina, com elementos interativos nos livros.',
    ageMin: 12, // 1y
    ageMax: 35 // 2y and 11 months
  },
  {
    category: 'B1',
    image: getImage('preLeitor'),
    title: 'Pré-leitor',
    suggestion: 'Sugerido para 3 anos a 3 anos e 11 meses',
    description:
      'Estímulo à fantasia e à autonomia, além de temas como a construção da identidade, a socialização e a rotina.',
    ageMin: 36, // 3y
    ageMax: 47 // 3y and 11 months
  },
  {
    category: 'B2',
    image: getImage('leitorIniciante'),
    title: 'Leitor Iniciante',
    suggestion: 'Sugerido para 4 anos a 5 anos e 11 meses',
    description:
      'Estímulo à pré-alfabetização de forma natural. Trabalha temas pertinentes, como a socialização, a solidariedade e a empatia.',
    ageMin: 48, // 4y
    ageMax: 71 // 5y and 11 months
  },
  {
    category: 'C',
    image: getImage('leitorProcesso'),
    title: 'Leitor em Processo',
    suggestion: 'Sugerido para 6 anos a 7 anos e 11 meses',
    description:
      'Promoção da reflexão sobre autonomia, pensamento crítico, moral e ética. Estimula alfabetização e interpretação de texto.',
    ageMin: 72, // 6y
    ageMax: 95 // 7y and 11 months
  },
  {
    category: 'D',
    image: getImage('LeitorFluente'),
    title: 'Leitor Fluente',
    suggestion: 'Sugerido para 8 anos a 9 anos e 11 meses',
    description:
      'Temas relativos às regras, resolução de conflitos e à cooperação, tão importantes para enfrentar os desafios dessa fase da vida.',
    ageMin: 96, // 8y
    ageMax: 119 // 9y and 11 months
  },
  {
    category: 'E',
    image: getImage('leitorCritico'),
    title: 'Leitor Crítico',
    suggestion: 'Sugerido para 10 a 12 anos',
    description:
      'Estímulo ao senso crítico, à interpretação de texto e à habilidade de fazer conexões, tornando-se um leitor mais autônomo e fluente.',
    ageMin: 120, // 10y
    ageMax: 144 // 12y
  }
]
