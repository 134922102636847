import { useEffect } from 'react'

declare global {
  interface Element {
    href: string
  }
}

export const HeaderSelector = () => {
  useEffect(() => {
    if (localStorage.getItem('@pk:whitelabel') === '"flap"') {
      document.title = 'Checkout Flap'
      const link = document.querySelector("link[rel~='icon']")
      if (link !== null) {
        link.href = '/favicon-flap.ico'
      }
    }
  }, [])
  return <></>
}
