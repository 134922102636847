import { PromotionBin, PromotionBins } from 'types/promotion'
import env from 'settings/env'

const PROMOTION_BINS: PromotionBins = {
  // development: [{ bin: '555555', promotion: 'credit-card-discount' }],
  production: [
    { bin: '409007', promotion: 'parceria_banco_bv' },
    { bin: '409601', promotion: 'parceria_banco_bv' },
    { bin: '409602', promotion: 'parceria_banco_bv' },
    { bin: '409603', promotion: 'parceria_banco_bv' },
    { bin: '409921', promotion: 'parceria_banco_bv' },
    { bin: '409922', promotion: 'parceria_banco_bv' },
    { bin: '425890', promotion: 'parceria_banco_bv' },
    { bin: '445015', promotion: 'parceria_banco_bv' },
    { bin: '446122', promotion: 'parceria_banco_bv' },
    { bin: '482455', promotion: 'parceria_banco_bv' },
    { bin: '482456', promotion: 'parceria_banco_bv' },
    { bin: '482457', promotion: 'parceria_banco_bv' },
    { bin: '482458', promotion: 'parceria_banco_bv' }
  ]
}

export const PROMOTION_BIN: Array<PromotionBin> =
  PROMOTION_BINS[env.ENVIRONMENT]
