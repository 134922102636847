import { ReactNode, createContext, useContext } from 'react'
import { getSubscriptionFromId } from 'services/api/subscription'
import { countDiffInMonth } from 'utils/date'
import CountDiffInMonth from 'utils/date/CountDiffInMonth'
import { CategoriesList } from 'data/Categories'
import { getProfileUser } from 'services/api/user'
import { AxiosResponse } from 'axios'
import { Child } from 'types'
import { verifyOrder } from 'services/api/checkout'
import { useAppDispatch, useAppSelector } from './useStore'
import { selectPlans, setPlanSelectedDetails } from 'store/modules/Plans.store'
import {
  selectSteps,
  setSubscriptionId,
  setCid
} from 'store/modules/Steps.store'
import { selectTheme } from 'store/modules/Theme.store'
import {
  addKidsActiveInChild,
  selectKids,
  setKidsActive
} from 'store/modules/Kids.store'
import { setDeliveryAddress } from 'store/modules/Address.store'
import { changePlan } from 'services/api/modules/Checkout/resources/ChangePlan'
import { changeChildren } from 'services/api/modules/Checkout/resources/ChangeChildren'
import { formatedKids } from 'utils/format'

type MagicLinkContextData = {
  getSubscription: (
    dataToGetSubscription: DataToGetSubscription
  ) => Promise<AxiosResponse>

  getProfile(token: string): Promise<AxiosResponse>

  formatChild(child): Child[]

  getVerifyOrder(token: string, planSku?: string, kids?: Child[]): Promise<void>
}

export const MagicLinkContext = createContext({} as MagicLinkContextData)

export type DataToGetSubscription = {
  token: string
  subscriptionId: number
}

export const MagicLinkProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch()
  const { theme } = useAppSelector(selectTheme)

  const project = theme.name

  async function getSubscription(
    dataToGetSubscription: DataToGetSubscription
  ): Promise<AxiosResponse> {
    try {
      const response = await getSubscriptionFromId(dataToGetSubscription)

      return response
    } catch (error) {
      throw new Error('Error to get subscription')
    }
  }

  async function getProfile(token: string): Promise<AxiosResponse> {
    const response = await getProfileUser(token)

    return response
  }

  function formatChild(child) {
    const mapped: Child[] = child.map((child) => {
      const diffMonth = CountDiffInMonth(child.birthday)
      let categorySelected = CategoriesList[0]

      if (diffMonth > -1) {
        categorySelected = CategoriesList.filter(
          (item) =>
            item.ageMin <= countDiffInMonth(child.birthday) &&
            item.ageMax >= countDiffInMonth(child.birthday)
        )[0]
      }

      if (!categorySelected) {
        categorySelected = CategoriesList[CategoriesList.length - 1]
      }

      return {
        id: child.id,
        name: child.name,
        birthday: new Date(child.birthday),
        didatic_age: new Date(child.didactic_birthday),
        type: categorySelected.category,
        gender: String(child.gender)
      }
    })

    return mapped
  }

  async function getVerifyOrder(
    token: string,
    planSku?: string,
    kids?: Child[]
  ) {
    const response = await verifyOrder(token, project)

    if (!response || !kids || kids.length <= 0) {
      throw new Error('Error to get verify order')
    }

    if (response?.id && response?.plan?.slug) {
      dispatch(setSubscriptionId(response.id))
      dispatch(setCid(response.cid))
      const orderId = response?.id
      const payloadChangePlan = { plan_sku: planSku ?? response.plan.slug }
      const payloadChangeChildren = { children: kids }

      await changePlan({
        token,
        orderId,
        payload: payloadChangePlan
      })

      await changeChildren({
        token,
        orderId,
        payload: payloadChangeChildren
      })
    }

    if (response?.children) {
      const formatBirthday = new Date(response.children[0].birthday!)
      const formatDidatic_age = new Date(
        response.children[0].didactic_birthday!
      )
      dispatch(
        setKidsActive({
          child: {
            name: response.children[0].name!,
            gender: response.children[0].gender!.toString(),
            birthday: formatBirthday,
            didatic_age: formatDidatic_age
          }
        })
      )

      dispatch(addKidsActiveInChild())
    }

    if (response?.address) {
      dispatch(setDeliveryAddress(response.address))
    }
    if (response?.id) {
      dispatch(setSubscriptionId(response.id))
    }
    if (response?.cid) {
      dispatch(setCid(response.cid))
    }
    if (response?.plan) {
      dispatch(setPlanSelectedDetails(response.plan))
    }
  }

  return (
    <MagicLinkContext.Provider
      value={{
        getSubscription,
        getProfile,
        formatChild,
        getVerifyOrder
      }}
    >
      {children}
    </MagicLinkContext.Provider>
  )
}

export const useMagicLink = () => {
  const context = useContext(MagicLinkContext)
  if (!context) {
    throw new Error(
      'Para usar o useMagicLink, é obrigatório o usuário do Provider'
    )
  }
  return context
}
