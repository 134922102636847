import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

type FooterState = {
  color: string
  primary: string
}

const initialState: FooterState = {
  color: '#FFFFFF',
  primary: '#8700A4'
}

const footer = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    setColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload
    },
    setColorPrimary: (state, action: PayloadAction<string>) => {
      state.primary = action.payload
    },
    reset: () => initialState
  }
})

export const { setColor, setColorPrimary, reset } = footer.actions

export const selectFooter = (state: RootState) => state.footer

export default footer.reducer
