export default {
  API_URL: import.meta.env.VITE_API_URL as string,
  API_LEITURINHA_CHECKOUT_URL: import.meta.env
    .VITE_API_LEITURINHA_CHECKOUT_URL as string,
  API_VINDI_URL: import.meta.env.VITE_API_VINDI_URL as string,
  API_ZIPCODE_CHECK: import.meta.env.VITE_API_ZIPCODE_CHECK as string,
  VINDI_API_TOKEN: import.meta.env.VITE_VINDI_API_TOKEN as string,
  API_LEGACY_RECOVER_PASSWORD: import.meta.env
    .VITE_API_LEGACY_RECOVER_PASSWORD as string,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT as string,
  EXPIRES_TIME: import.meta.env.VITE_STORAGE_EXPIRES as number,
  ADYEN: {
    CLIENT_KEY: import.meta.env.VITE_ADYEN_CLIENT_KEY as string,
    ENV: import.meta.env.VITE_ADYEN_ENV as string
  }
} as const
