import { imageList } from 'data/ImageList'

export default function GetImage(name: string) {
  const image = imageList.find((image) => image.name === name)

  if (image) {
    return image.url
  }

  return ''
}
