import * as storage from 'services/storage'
import { BodyObjectPlans } from 'types'
import { PlanType } from 'types/enums'

export function getPeriodPlan(plansOptions: BodyObjectPlans): string[] {
  if (plansOptions) {
    const plansOptionsMapped = Object.keys(plansOptions)
      .map((key) => plansOptions[key])
      .map((item) => Object.keys(item))

    const uniqueNames: string[] = []
    plansOptionsMapped.forEach((item) => {
      item.forEach((item) => {
        if (!uniqueNames.includes(item)) {
          uniqueNames.push(item)
        }
      })
    })
    const result = uniqueNames.map(Number)
    result.sort((a, b) => a - b)
    return result.map(String)
  }
  return []
}

export function formatedPlans(plans) {
  if (plans) {
    const formatedPlans = []
    Object.keys(plans).map((period) => {
      Object.keys(plans[period]).map((plan) => {
        if (plans[period][plan]) {
          const obj = {
            plan: plans[period][plan].type,
            period: plans[period][plan].periodicity,
            details: plans[period][plan]
          }
          formatedPlans.push(obj)
        }
      })
    })
    return formatedPlans
  }
}

export function getWhitelabelLocalStorage(): string {
  const whitelabel = storage.get('@pk:whitelabel', false) as string

  if (whitelabel) {
    const formatedWhitelabel = whitelabel.replace(/"/g, '')
    return formatedWhitelabel
  }

  return whitelabel
}

export function getColorPlan(plan?: PlanType): string {
  switch (plan) {
    case PlanType.MINI:
      return 'blue'
    case PlanType.UNI:
      return 'red'
    case PlanType.DUNI:
      return 'purple'
    case PlanType.FLAP:
      return '#5B2F80'
    default:
      return '#5B2F80'
  }
}
