import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

type ToastPosition =
  | 'top-right'
  | 'top-center'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'bottom-left'

type ToastState = {
  toast: {
    position: ToastPosition
    hideProgressBar: boolean
    color: {
      text: string
      background: string
      progressStyle: string
    }
  }
}

const initialState: ToastState = {
  toast: {
    position: 'top-center',
    hideProgressBar: true,
    color: {
      text: 'white',
      background: 'red',
      progressStyle: 'pink'
    }
  }
}

const toast = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action: PayloadAction<ToastState>) => {
      state.toast = action.payload.toast
    },
    reset: () => initialState
  }
})

export const { setToast, reset } = toast.actions

export const selectToast = (state: RootState) => state.toast

export default toast.reducer
