import axios, { AxiosResponse } from 'axios'
import { PaymentData, PaymentResponse, CryptoPaymentResponse } from 'types'
import env from 'settings/env'
import { toBase64 } from 'utils/payments'
const API_PAYMENT_URL = env.API_URL
const API_VINDI_URL = env.API_VINDI_URL
const VINDI_API_TOKEN = env.VINDI_API_TOKEN

async function sendVindiPayment(data: PaymentData): Promise<string> {
  const options = {
    method: 'POST',
    url: API_VINDI_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${env.ENVIRONMENT === 'production'
          ? toBase64(`${VINDI_API_TOKEN}:`)
          : VINDI_API_TOKEN
        }`
    },
    data: { ...data }
  }

  const response = await axios.request(options)
  return response.data.payment_profile.gateway_token
}

async function sendPayment(
  payment_method: string,
  orderId: number,
  token: string,
  cpf?: string,
  orderToken?: string | null,
  pin?: string | null,
  promoitems?: string[] | null,
  ambassadorCode?: string | null,
  promotion?: string | null,
  recaptcha?: string | null,
  fingerprint?: string | null
): Promise<PaymentResponse> {
  const options = {
    method: 'POST',
    url: API_PAYMENT_URL + `/checkout/${orderId}/payment`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {
      token: orderToken,
      payment_method: payment_method,
      cpf: cpf,
      pin: pin,
      promoitems: promoitems,
      ambassador_code: ambassadorCode,
      promotion_slug: promotion,
      g_recaptcha_response: recaptcha,
      buuid: fingerprint
    }
  }

  const response = await axios.request(options)
  return response.data.body
}

async function sendCryptoPayment(
  accountId: number,
  token: string
): Promise<CryptoPaymentResponse> {
  const options = {
    method: 'POST',
    url: API_PAYMENT_URL + `/xdbchain/${accountId}/paymentRequestToken`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const response = await axios.request(options)
  return response.data.body
}

async function checkPaymentStatus(
  accountId: number,
  token: string
): Promise<string> {
  const options = {
    method: 'GET',
    url: API_PAYMENT_URL + `/checkout/${accountId}/status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const response = await axios.request(options)
  return response.data.body
}

export { sendPayment, sendVindiPayment, sendCryptoPayment, checkPaymentStatus }
