import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { CategoriesList } from 'data/Categories'
import { CategoryData, Child, KidsType } from 'types'
import { getCategoryFromDate } from 'utils/format/CategoryByBithday'

type KidsState = {
  presentation: {
    isKidsStep: boolean
    isDuni: boolean
    isEditChild: boolean
  }
  model: {
    kids: Child[]
    kidsActive: KidsType
    agreeTerm: boolean
  }
}

const initialState: KidsState = {
  presentation: {
    isKidsStep: true,
    isDuni: false,
    isEditChild: false
  },
  model: {
    kidsActive: {
      child: {
        name: '',
        gender: ''
      }
    },
    kids: [],
    agreeTerm: false
  }
}

const kids = createSlice({
  name: 'kids',
  initialState,
  reducers: {
    setKidsActive: (state, action: PayloadAction<KidsType>) => {
      state.model.kidsActive = action.payload
    },
    setAgreeTerm: (state, action: PayloadAction<boolean>) => {
      state.model.agreeTerm = action.payload
    },
    setIsDuni: (state, action: PayloadAction<boolean>) => {
      state.presentation.isDuni = action.payload
    },
    setKidsEdit: (state, action: PayloadAction<number>) => {
      const kidsFind = state.model.kids.find(
        (_item, index) => index === action.payload
      )

      if (!kidsFind) return
      state.presentation.isEditChild = true

      state.model.kidsActive = {
        child: {
          ...state.model.kids[action.payload]
        }
      }

      if (kidsFind.category) {
        state.model.kidsActive.category = CategoriesList.find(
          (c) => c.category === kidsFind?.category
        )
      }

      const index = state.model.kids.indexOf(kidsFind as Child)
      if (index > -1) {
        state.model.kids.splice(index, 1)
      }
    },
    addKidsActiveInChild: (state) => {
      if (
        state.model.kidsActive.child.name !== '' &&
        state.model.kidsActive.child.gender !== ''
      ) {
        state.model.kids.push({
          ...state.model.kidsActive.child,
          category: state.model.kidsActive.category?.category
        })
        state.model.kidsActive = {
          child: {
            name: '',
            birthday: undefined,
            gender: ''
          }
        }

        if (state.presentation.isDuni) {
          if (state.model.kids.length > 1) {
            state.presentation.isEditChild = false
          }
        } else {
          if (state.model.kids.length <= 1) {
            state.presentation.isEditChild = false
          }
        }
      }
    },
    addKidsInChild: (state, action: PayloadAction<Child[]>) => {
      state.model.kids = action.payload
    },

    removeLastChild: (state) => {
      state.model.kids.pop()
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.presentation.isEditChild = action.payload
    },
    resetKids: (state) => {
      state.presentation.isEditChild = true
      state.model = initialState.model
    },
    reset: () => initialState
  }
})

export const {
  setKidsActive,
  addKidsActiveInChild,
  addKidsInChild,
  removeLastChild,
  setKidsEdit,
  setAgreeTerm,
  setIsDuni,
  setIsEdit,
  resetKids,
  reset
} = kids.actions

export const selectKids = (state: RootState) => state.kids

export default kids.reducer
