import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch } from 'hooks/useStore'

import { reset as resetAddress } from 'store/modules/Address.store'
import { reset as resetFooter } from 'store/modules/Footer.store'
import { reset as resetKids } from 'store/modules/Kids.store'
import { reset as resetPayment } from 'store/modules/Payment.store'
import { reset as resetPin } from 'store/modules/Discount.store'
import { reset as resetPlans } from 'store/modules/Plans.store'
import { reset as resetSteps } from 'store/modules/Steps.store'
import { reset as resetTheme } from 'store/modules/Theme.store'
import { reset as resetToast } from 'store/modules/Toast.store'
import Loading from 'pages/Loading'
import routes from 'routes'
import { useNavigate } from 'react-router-dom'

export default function ResetToken() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(resetPayment())
    dispatch(resetSteps())
    // dispatch(resetAddress())
    // dispatch(resetFooter())
    // dispatch(resetKids())
    // dispatch(resetPin())
    // dispatch(resetPlans())
    // dispatch(resetTheme())
    // dispatch(resetToast())

    navigate(routes.signature.registration)
  }, [])

  return <Loading message="Reninciando a aplicação" />
}
