export default {
  GET: {
    checkout: {
      method: 'GET',
      uri: '/checkout',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    plan: {
      method: 'GET',
      uri: '/checkout/plan/:id',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  },
  POST: {
    checkout: {
      method: 'POST',
      uri: '/checkout',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `
      }
    },
    children: {
      method: 'POST',
      uri: '/checkout/:id/children',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `
      }
    },
    plan: {
      method: 'POST',
      uri: '/checkout/:id/plan',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `
      }
    },
    address: {
      method: 'POST',
      uri: '/checkout/:id/address',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `
      }
    },
    payment: {
      method: 'POST',
      uri: '/checkout/:id/payment',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `
      }
    },
    mediaSources: {
      method: 'POST',
      uri: '/checkout/:id/media-sources',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `
      }
    },
    validatePromoitems: {
      method: 'POST',
      uri: '/checkout/:id/promoitem',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `
      }
    }
  }
} as const
