import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { DiscountBody } from 'types/Responses/DiscountResponse'

type DiscountState = {
  promotion?: string | null
  pinCode?: string | null
  discountResponse?: DiscountBody
}

const initialState: DiscountState = {
  promotion: undefined,
  pinCode: undefined,
  discountResponse: undefined
}

const discount = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    setPromotion: (state, action: PayloadAction<string | null | undefined>) => {
      state.promotion = action.payload
    },
    setPinCode: (state, action: PayloadAction<string | null | undefined>) => {
      state.pinCode = action.payload
    },
    setDiscountResponse: (
      state,
      action: PayloadAction<DiscountBody | undefined>
    ) => {
      state.discountResponse = action.payload
    },
    reset: () => initialState
  }
})

export const { setDiscountResponse, setPinCode, setPromotion, reset } =
  discount.actions

export const selectDiscount = (state: RootState) => state.discount

export default discount.reducer
