import { createRoot } from 'react-dom/client'
import Providers from 'providers'
import Router from 'routes/Router'
import 'tailwindcss/tailwind.css'
import { StartTrackingEvents } from 'services/events'
import { HeaderSelector } from 'components/HeaderSelector'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://bb67a061e364a90755f23d5ff937321d@o4506577012391936.ingest.us.sentry.io/4506577014751232',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/checkout-staging\.leiturinha\.com\.br/,
        /^https:\/\/checkout\.leiturinha\.com\.br/
      ]
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        /^https:\/\/leiturinha-checkout\.api\.leiturinha\.com\.br/,
        /^https:\/\/leiturinha-checkout-staging\.api\.leiturinha\.com\.br/,
        /^https:\/\/jaiminho\.api\.dc\.pkds\.it/
      ],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header']
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

root.render(
  <Providers>
    <HeaderSelector />
    <StartTrackingEvents />
    <Router />
  </Providers>
)
