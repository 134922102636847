import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { UrlParams } from 'types/url_params'

export const initialState: UrlParams = {
  src: null,
  source: null,
  utmSource: null,
  utmMedium: null,
  utmContent: null,
  utmCampaign: null,
  campaignId: null,
  adsetId: null,
  adId: null,
  variation: '',
  variationType: '',
  createdAt: null,
  ambCode: null,
  orderBump: null,
  promoItems: null
}

const urlParams = createSlice({
  name: 'urlParams',
  initialState,
  reducers: {
    setSrc: (state, action: PayloadAction<string | null>) => {
      state.src = action.payload
    },
    setSource: (state, action: PayloadAction<string | null>) => {
      state.source = action.payload
    },
    setUtmSource: (state, action: PayloadAction<string | null>) => {
      state.utmSource = action.payload
    },
    setUtmMedium: (state, action: PayloadAction<string | null>) => {
      state.utmMedium = action.payload
    },
    setUtmContent: (state, action: PayloadAction<string | null>) => {
      state.utmContent = action.payload
    },
    setUtmCampaign: (state, action: PayloadAction<string | null>) => {
      state.utmCampaign = action.payload
    },
    setCampaignId: (state, action: PayloadAction<string | null>) => {
      state.campaignId = action.payload
    },
    setAdsetId: (state, action: PayloadAction<string | null>) => {
      state.adsetId = action.payload
    },
    setAdId: (state, action: PayloadAction<string | null>) => {
      state.adId = action.payload
    },
    setPromoItems: (state, action: PayloadAction<string[] | null>) => {
      state.promoItems = action.payload
    },
    setVariation: (state, action: PayloadAction<string | null>) => {
      state.variation = action.payload
    },
    setVariationType: (state, action: PayloadAction<string | null>) => {
      state.variationType = action.payload
    },
    setCreated: (state, action: PayloadAction<Date | null>) => {
      state.createdAt = action.payload
    },
    setAmbCode: (state, action: PayloadAction<string | null>) => {
      state.ambCode = action.payload
    },
    setOrderBump: (state, action: PayloadAction<string | null>) => {
      state.orderBump = action.payload
    },
    reset: () => initialState
  }
})

export const {
  setSrc,
  setSource,
  setUtmSource,
  setUtmMedium,
  setUtmContent,
  setUtmCampaign,
  setCampaignId,
  setAdsetId,
  setAdId,
  setPromoItems,
  setVariation,
  setVariationType,
  setCreated,
  setAmbCode,
  setOrderBump,
  reset
} = urlParams.actions

export const selectUrlParams = (state: RootState) => state.urlParams

export default urlParams.reducer
