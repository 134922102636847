import { useAppSelector } from 'hooks/useStore'
import { selectTheme } from 'store/modules/Theme.store'

import { getImage } from 'utils/images'

type LoadingProps = {
  isTextShow?: boolean
  message?: string
}

export default function Loading({
  isTextShow = false,
  message = 'Estamos finalizando sua assinatura ...'
}: LoadingProps) {
  const { theme } = useAppSelector(selectTheme)
  return (
    <div
      className="fixed h-screen w-screen bg-cover bg-center z-50"
      style={{
        backgroundImage: `url(${getImage('bgblur')})`
      }}
    >
      <div className="flex h-screen w-screen flex-col items-center justify-center">
        <div className="mb-8 animate-bounce">
          <img src={getImage(theme.loading.img)} alt="Aguarde carregando..." />
        </div>
        {isTextShow && (
          <div>
            <p className="text-center text-3xl font-bold text-purple">
              {message}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
