import { BrandFlagCardType } from 'utils/payments'
import { FaCcAmex, FaCcMastercard, FaCcVisa } from 'react-icons/fa'
import { IoCard } from 'react-icons/io5'
import { CreditCardElo } from 'components/Icon/CreditCardElo'

type CreditCardFlagProps = {
  className?: string
  brandFlag: BrandFlagCardType
  color?: string
}

export const useCreditCard = () => {
  function getCreditCardFlag({
    className = 'w-full h-10',
    brandFlag,
    color = '#006BCE'
  }: CreditCardFlagProps) {
    switch (brandFlag) {
      case 'visa':
        return <FaCcVisa className={className} color={color} />
      case 'mastercard':
        return <FaCcMastercard className={className} color={color} />
      case 'amex':
        return <FaCcAmex className={className} color={color} />
      case 'elo':
        return <CreditCardElo className={className} color={color} />
      default:
        return <IoCard className={className} color="#C4C4C4" />
    }
  }
  return { getCreditCardFlag }
}
