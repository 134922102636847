import {
  differenceInMonths,
  differenceInHours,
  differenceInMinutes,
  isPast
} from 'date-fns'

export default function CountDiffInMonth(date: Date) {
  const dateNow = new Date()
  return differenceInMonths(dateNow, date)
}
export function CountDiffInHours(date: Date) {
  const dateNow = new Date()
  return differenceInHours(dateNow, date)
}

export function CountDiffInMinutes(date: Date) {
  const dateNow = new Date()
  return differenceInMinutes(dateNow, date)
}

export function IsExpired(date: Date) {
  return isPast(date)
}
